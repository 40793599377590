import { useEffect, useState } from "react";
import { getManagementData } from "../../services/axios.function";

const useManagementHook = () => {

    const [teamMembers, setTeamMembers] = useState([]);

    const fetchManagementData = async () => {
        try {
            const result = await getManagementData()
            setTeamMembers(result?.data)
        } catch (error) {
            console.log("FETCH MANAGEMENT ERROR===>>>", error);
        }
    }

    useEffect(() => {
        fetchManagementData()
    }, [])

  return {
    teamMembers
  };
};

export default useManagementHook;
