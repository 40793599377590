import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import CompanyHook from "./Company.hook";
import { ImageLink } from "../../constants";

const CompanyDetails = () => {
  const [data, setData] = useState({});
  const companyUrl = useLocation().pathname.split("/")[2];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CompanyHook(companyUrl);
        if (response) {
          setData(response);
        } else {
          console.log("No data found or error occurred.");
        }
      } catch (error) {
        console.error("Error while fetching company data:", error);
      }
    };

    fetchData();
  }, [companyUrl]);

  const desktopImgs = Array.isArray(data?.desktopImg) ? data?.desktopImg : [];

  return (
    <>
      <div className="group-about-w">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-4 mb-md-4 mb-md-0 order-md-1">
              <motion.div
                className="row"
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: false }}
              >
                <div className="image-container">
                  <img
                    src={ImageLink(`${data?.bannerImg}`)}
                    alt="Company"
                    className="img-fluid aos-init"
                  />
                </div>
              </motion.div>
            </div>
            <div className="col-lg-7 mb-4 mb-md-4 mb-md-0 order-md-2">
              <motion.div
                className="row"
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: false }}
              >
                <div className="col-md-12">
                  <h3 className="group-about-title">{data?.name}</h3>
                  <p
                    dangerouslySetInnerHTML={{ __html: data?.shortDescription }}
                  ></p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {data?.longDescription && (
        <div className="container about-section">
          <p dangerouslySetInnerHTML={{ __html: data?.longDescription }}></p>
        </div>
      )}
      <div className="container">
        <div className="row pt-40">
          {desktopImgs.slice(0, 4)?.map((img, index) =>
            !data?.showInCompany ? null : (
              <div
                className="col-lg-6 col-md-4 mb-4 mb-md-4 mb-md-0"
                key={index}
              >
                <motion.div
                  initial={{
                    opacity: 0,
                    y: 100,
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 1,
                    },
                  }}
                  viewport={{ once: false }}
                >
                  <div className="image-container">
                    <img
                      src={ImageLink(`${img}`)}
                      alt={`Company  ${index + 1}`}
                      className="img-fluid aos-init"
                    />
                  </div>
                </motion.div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
