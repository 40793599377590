import React from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import useNavbarHook from "./Navbar.hook";
import { ImageLink } from "../../constants";
import Logo from "../../Assets/images/logo/lgo-h.svg";
import { FiHome } from "react-icons/fi";


const Navbar = ({ settingData, navData }) => {
  const {
    openAccordion,
    toggleAccordion,
    openMenu,
    handleToggle,
    handleCloseMenu,
    scrolled,
    location,
  } = useNavbarHook();

  return (
    <>
      <header>
        <div
          id="sticky-header"
          className={`menu-area menu-style-three fixed-top ${scrolled ? "bg-white" : "transparent-header"
            }`}
        >
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="mobile-nav-toggler" onClick={handleToggle}>
                  <i className="fas fa-bars" />
                </div>

                <div className={`menu-wrap ${openMenu ? "open" : ""}`}>
                  <nav className="menu-nav">
                    <div className="row g-0 align-items-center">
                      <div className="col-lg-2">
                        <div className="logo mx-lg-auto">
                          <Link to="/">
                            {settingData?.data?.websiteLogo ? (
                              <img
                                src={ImageLink(settingData?.data?.websiteLogo)}
                                alt="Logo"
                              />
                            ) : (
                              <img src={Logo} alt="Logo" />
                            )}
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div className="navbar-wrap main-menu d-none d-lg-flex justify-content-center">
                          <ul className="navigation d-flex justify-content-center ms-0 mb-0">
                            {(navData?.length > 0) && (
                              <li className="menu-item-has-children">
                                <Link to="/" className="text-decoration-none text-capitalize">
                                  <FiHome className="fs-5" style={{ marginTop: "-5px" }} />
                                </Link>
                              </li>
                            )
                            }

                            {navData?.length > 0 &&
                              navData
                                ?.filter((item) => item?.showInHeader)
                                ?.map((item) => (
                                  <li
                                    key={item?._id}
                                    className={`menu-item-has-children ${location?.pathname === `/${item?.menuUrl}`
                                      ? "active"
                                      : ""
                                      }`}
                                  >
                                    <Link
                                      to={item?.submenu ? "#" : item?.menuUrl}
                                      className="text-decoration-none text-capitalize"
                                      onClick={(e) => {
                                        if (item?.submenu) {
                                          e.preventDefault();
                                        }
                                      }}
                                    >
                                      {item?.menuName}
                                      {item?.submenu && (
                                        <i className="fas fa-chevron-down ms-1"></i>
                                      )}
                                    </Link>
                                    {item?.submenu && (
                                      <ul className="sub-menu">
                                        {item?.submenu
                                          ?.filter(
                                            (subItem) => subItem?.showInHeader
                                          )
                                          ?.map((subItem) => (
                                            <li key={subItem?._id}>
                                              {item?.menuName === "Investors" ? (
                                                <Link to="#">
                                                  {subItem?.menuName}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`${item?.menuUrl}/${subItem?.menuUrl}`}
                                                >
                                                  {subItem?.menuName}
                                                </Link>
                                              )}
                                            </li>
                                          ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>

                <div
                  className={`mobile-menu d-lg-none mobile-menu-bg ${openMenu ? "open" : ""
                    }`}
                >
                  <nav className="menu-box navbar-collapse">
                    <div className="mobile-menu-header">
                      <div className="nav-logo">
                        <Link to="/" onClick={handleCloseMenu}>
                          <img
                            src={ImageLink(settingData?.data?.websiteLogo)}
                            alt="Logo"
                          />
                        </Link>
                      </div>
                      <div className="close-btn" onClick={handleToggle}>
                        <i className="fas fa-times text-white" />
                      </div>
                    </div>
                    <div className="menu-outer">
                      <Accordion
                        flush
                        open={openAccordion}
                        toggle={(id) => toggleAccordion(id)}
                      >
                        {navData?.length > 0 &&
                          navData
                            ?.filter((item) => item.showInHeader)
                            ?.map((item) => (
                              <AccordionItem key={item?._id}>
                                <AccordionHeader targetId={item?._id}>
                                  <div className="d-flex justify-content-between w-100">
                                    {!item?.submenu ? (
                                      <Link
                                        to={item?.menuUrl}
                                        className="text-decoration-none text-capitalize nav-accordion-title"
                                        onClick={handleCloseMenu}
                                      >
                                        {item?.menuName}
                                      </Link>
                                    ) : (
                                      <span className="text-capitalize nav-accordion-title">
                                        {item?.menuName}
                                      </span>
                                    )}
                                    {item?.submenu && (
                                      <i
                                        className={`fas fa-chevron-down me-3 cursor-pointer ${openAccordion === item?._id ? "rotate-icon-2" : "reset-icon"
                                          }`}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleAccordion(item?._id);
                                        }}
                                      />
                                    )}
                                  </div>
                                </AccordionHeader>
                                {item?.submenu && (
                                  <AccordionBody accordionId={item?._id}>
                                    <ul className="list-wrap">
                                      {item?.submenu
                                        ?.filter((subItem) => subItem?.showInHeader)
                                        ?.map((subItem) => (
                                          <li key={subItem?._id}>
                                            {item?.menuName === "Investors" ? (
                                              <Link to="#" onClick={handleCloseMenu}>
                                                {subItem?.menuName}
                                              </Link>
                                            ) : (
                                              <Link to={`${item?.menuUrl}/${subItem?.menuUrl}`} onClick={handleCloseMenu}>
                                                {subItem?.menuName}
                                              </Link>
                                            )}
                                          </li>
                                        ))}
                                    </ul>
                                  </AccordionBody>
                                )}
                              </AccordionItem>
                            ))}
                      </Accordion>

                    </div>
                  </nav>
                </div>
                <div
                  className={`menu-backdrop ${openMenu ? " open" : ""}`}
                  onClick={handleToggle}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="header-space"></div>
    </>
  );
};

export default Navbar;
