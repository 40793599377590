import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ImageLink } from "../../constants";
import { getExcellenceData } from "../../services/axios.function";

const WeBelieve = ({ excellence }) => {

  let title = "Committed to excellence  <br /> in everything we do.";
  if (excellence) {
    title = excellence?.title || title;
  }

  const [excellenceData, setExcellenceData] = useState([]);
  const [expanded, setExpanded] = useState(null);

  const fetchExcellenceData = async () => {
    try {
      const result = await getExcellenceData();
      setExcellenceData(result?.data?.data);
    } catch (error) {
      console.log("FETCH EXCELLENCE ERROR===>>>", error);
    }
  };

  useEffect(() => {
    fetchExcellenceData();
  }, []);

  const handleReadMore = (index) => {
    setExpanded(index === expanded ? null : index);
  };

  return (
    <div className="section-pt-80">
      <motion.div
        className="container we-believe-container"
        initial={{
          opacity: 0,
          y: 100,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 1,
          },
        }}
        viewport={{ once: false }}
      >
        <div className="row align-items-center mb-5">
          <div className="col-xl-9 col-lg-9 col-md-8">
            <div className="section-title mb-3 mb-lg-0 mb-md-0">
              <h2 className="title text-dark" dangerouslySetInnerHTML={{ __html: title }}></h2>

            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-4 text-lg-end text-start">
            <div className="we-believe-left">
              <p className="m-0">We Believe In</p>
            </div>
          </div>
        </div>
        <div className="row">
          {excellenceData?.length > 0 &&
            excellenceData
              ?.filter((belief) => belief?.isActive)
              ?.map((belief, index) => (
                <motion.div
                  className="col-lg-3 col-md-6 mb-4"
                  key={belief?._id}
                  initial={{
                    opacity: 0,
                    x: 25,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.5,
                      delay: index * 0.2,
                    },
                  }}
                  viewport={{ once: false }}
                >
                  <div className={`card webelieve-card we-${belief?.position}`}>
                    <div className="webelieve-section">
                      <img
                        src={ImageLink(belief?.icon)}
                        alt={belief?.name}
                        className="mb-4 img-fluid"
                      />
                      <h4 className="webelieve-title text-capitalize">
                        {belief?.name}
                      </h4>
                      <p
                        className={`webelieve-description ${expanded === index ? "" : "text-truncate-three"
                          }`}
                      >
                        {belief?.description}
                      </p>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleReadMore(index);
                        }}
                      >
                        <small className="uppercase text-decoration-underline underline-offset read-more">
                          {expanded === index ? "Show Less" : "Read More"}
                        </small>
                      </Link>
                    </div>
                  </div>
                </motion.div>
              ))}
        </div>
      </motion.div>
    </div>
  );
};

export default WeBelieve;
