import { useEffect, useState } from "react";
import { getInvestorData, getInvestorTypeData } from "../../services/axios.function";

const useInvestorHook = () => {

  const [investorsData, setInvestorsData] = useState([]);

  const [investorType, setInvestorType] = useState("all");

  const fetchInvestorsData = async () => {
    try {
      const response = await getInvestorData();
      setInvestorsData(response?.data?.data);
    } catch (error) {
      console.error("Something went wrong. Please try again!!");
    }
  };

  const fetchInvestorTypeData = async () => {
    try {
      const response = await getInvestorTypeData();
      setInvestorType(response?.data?.data);
    } catch (error) {
      console.error("Something went wrong. Please try again!!");
    }
  };


  useEffect(() => {
    fetchInvestorsData();
    fetchInvestorTypeData();
  }, []);

  return {
    investorsData,
    investorType
  };
};

export default useInvestorHook;
