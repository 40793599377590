import React from "react";
import { motion } from "framer-motion";

import Innovation from "../../Assets/images/img/Innovation.svg";
import Economies from "../../Assets/images/img/Economies.svg";
import Technology from "../../Assets/images/img/Technology.svg";
import Vision from "../../Assets/images/img/Vision.svg";
import Eye from "../../Assets/images/img/eye.png";
import useAboutHook from "./About.hook";

const Aboutpage = () => {

  const aboutData = [
    {
      id: 1,
      title: "Commitment to Sustainability",
      image: Innovation,
      dec: "We believe in integrating sustainable practices into our operations. Our investments in wind and solar energy reflect our commitment to environmental stewardship, ensuring that we not only meet the needs of our customers but also contribute positively to the planet.",
    },
    {
      id: 2,
      title: "Why Choose Eagle Group?",
      image: Economies,
      dec: `
        <ul>
          <li>Quality Products: We prioritize high-quality standards across our diverse range of textile products.</li>
          <li>Innovation: Our dedication to continuous innovation drives us to explore new technologies and methods.</li>
          <li>Customer Focus: We are committed to understanding and meeting the evolving needs of our customers.</li>
        </ul>
      `,
    },
    {
      id: 3,
      title: "Conclusion",
      image: Technology,
      dec: "At Eagle Group, our dedication to innovation and sustainability drives us to not only meet but exceed the expectations of our customers. We are proud of our heritage and excited about our future as we work towards becoming the most admired company in the textile industry. Join us on our journey towards excellence!",
    },
    // {
    //   id: 4,
    //   title: "Global Presence",
    //   image: Global,
    //   dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.It was popularity in the 1960s with the release of Least sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Laius PageMaker including versions of Lorem Ipsum.Lorem Ipsum is simply dummy text of the printing and typesetting industry, Lorem Ipsum is simply dummy text of the printing and typesetting industry, Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    // },
  ];

  const {
    activeData,
    setActiveData,
    activeAboutData } =
    useAboutHook(aboutData);

  return (
    <>
      <div className="container about-section">
        <motion.div
          className="row"
          initial={{
            opacity: 0,
            y: 100,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: false }}
        >
          <div className="col-md-6 about-border">
            <div className="about-title mb-0">
              <h1>
                we at <span>Eagle Group</span>
              </h1>
            </div>
          </div>
          <div className="col-md-6">
            <p className="about-dis">
              Eagle Group is a family-run, values-based textile company that has
              been at the forefront of innovation and quality since its
              inception in 1975. With three generations actively involved in
              management, we combine traditional values with modern practices to
              create a dynamic business environment that fosters growth,
              accountability, and excellence.
            </p>
          </div>
        </motion.div>
        <div className="row pt-60 pb-60">
          {aboutData?.length > 0 && aboutData?.map((data, index) => (
            <motion.div
              className={`col-lg-4 col-sm-6 col-md-6 mb-4 mb-md-4  mb-sm-0 ${data?.id === activeData ? "active" : ""
                }`}
              key={data?.id}
              onClick={() => setActiveData(data?.id)}
              initial={{
                opacity: 0,
                x: 25,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1,
                  delay: index * 0.2,
                },
              }}
              viewport={{ once: false }}
            >
              <div
                className={`about-card ${data?.id === activeData ? "border-active" : ""
                  }`}
              >
                <img src={data?.image} alt="Innovation" />
                <h6 className="about-card-title">{data?.title}</h6>
              </div>
            </motion.div>
          ))}

          <motion.div
            className="col-md-12"
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: false }}
          >
            {activeAboutData && (
              <p
                className="about-dis pt-30"
                dangerouslySetInnerHTML={{ __html: activeAboutData?.dec }}
              />
            )}
          </motion.div>
        </div>
        <div className="row pt-20 pb-80">
          <div className="col-md-6 mb-4">
            <div className="vision-mission p-lg-5 p-md-3 p-3">
              <motion.div
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: false }}
              >
                <div className="d-flex align-items-center pb-2">
                  <img src={Eye} alt="Vision" className="vision-mission-img" />
                  <h3 className="mx-2 pt-2 vision-mission-title">Vision</h3>
                </div>
                <p className="text-justify">
                  To be the most admired company by providing value-added
                  products and services, aiming to achieve a market cap of 1
                  billion USD by 2031.
                </p>
              </motion.div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="vision-mission p-lg-5 p-md-3 p-3">
              <motion.div
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: false }}
              >
                <div className="d-flex align-items-center pb-2">
                  <img
                    src={Vision}
                    alt="Mission"
                    className="vision-mission-img"
                  />
                  <h3 className="mx-2 pt-2 vision-mission-title">Mission</h3>
                </div>
                <p className="text-justify">
                  At Eagle Group, we are committed to continuous innovation and
                  delivering high-quality, diverse textile products that meet
                  the evolving needs of our customers. We foster a dynamic and
                  responsible business environment that values excellence,
                  sustainability, and customer satisfaction.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutpage;
