import { useEffect, useState } from "react";
import { getSocialMediaData } from "../../services/axios.function";

const useFooterHook = (products, settingData) => {

    const [socialMediaData, setSocialMediaData] = useState([]);
    const [open, setOpen] = useState("");
    const [isMobile, setIsMobile] = useState(false);
    const [firstColumnProducts, setFirstColumnProducts] = useState([]);
    const [secondColumnProducts, setSecondColumnProducts] = useState([]);

    const fetchSocialMediaData = async () => {
        try {
            const result = await getSocialMediaData();
            setSocialMediaData(result?.data);
        } catch (error) {
            console.log("FETCH SOCIAL MEDIA ERROR===>>>", error);
        }
    };

    useEffect(() => {
        fetchSocialMediaData();
    }, []);

    useEffect(() => {
        if (products?.length > 0) {
            const firstColumn = products.slice(0, 6);
            const secondColumn = products?.length > 6 ? products.slice(6, 12) : [];
            setFirstColumnProducts(firstColumn);
            setSecondColumnProducts(secondColumn);
        }
    }, [products]);

    const toggle = (id) => {
        if (open === id) {
            setOpen("");
        } else {
            setOpen(id);
        }
    };

    let emails = settingData?.data?.websiteEmail ? settingData?.data?.websiteEmail : ""
    let emailArray = emails.split(',');

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        socialMediaData,
        open,
        toggle,
        isMobile,
        firstColumnProducts,
        secondColumnProducts,
        emailArray
    };
};

export default useFooterHook;
