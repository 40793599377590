import { useEffect, useState } from "react";
import {
    getBannerData,
    getCompanyData,
    getMenuData,
    getProductData,
    getSettingData,
    getPageContentData,
    getHomeSettingData
} from "./services/axios.function";
import { useLocation } from "react-router-dom";

const useAppHook = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [allBanners, setAllBanners] = useState([]);
    const [matchedBanner, setMatchedBanner] = useState(null);
    const [settingData, setSettingData] = useState([]);
    const [products, setProducts] = useState([]);
    const [navData, setNavData] = useState([]);
    // const [rawData, setRawData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [companyData, setCompanyData] = useState([]);
    const [pageContent, setPageContent] = useState([]);
    const [homeSettingData, setHomeSettingData] = useState([]);

    const fetchBannerData = async () => {
        setLoading(true);
        try {
            const result = await getBannerData();
            const data = result?.data?.filter(banner => banner?.isActive !== false)
            setAllBanners(data);
            setLoading(false);
            return data;
        } catch (error) {
            console.log("FETCH BANNER ERROR===>>>", error);
            setLoading(false);
            setAllBanners([]);
            return [];
        }
    };

    const fetchSettingData = async () => {
        try {
            const result = await getSettingData();
            setSettingData(result?.data);
        } catch (error) {
            console.log("FETCH SETTING ERROR===>>>", error);
        }
    };

    const fetchProductsData = async () => {
        try {
            const result = await getProductData();
            setProducts(result?.data?.data);
        } catch (error) {
            console.log("GET PRODUCTS ERROR===>>>", error);
        }
    };

    const fetchMenuData = async () => {
        try {
            const result = await getMenuData();
            const data = result?.data?.data || [];

            setNavData(data);
        } catch (error) {
            console.log("FETCH MENU ERROR ===>>>", error);
        }
    };

    const fetchCompanyData = async () => {
        try {
            const result = await getCompanyData();
            setCompanyData(result?.data);
        } catch (error) {
            console.log("FETCH COMPANY ERROR===>>>", error);
        }
    };

    const fetchPageContentData = async () => {
        try {
            const result = await getPageContentData();
            setPageContent(result?.data);
        } catch (error) {
            console.log("FETCH PAGE CONTENT ERROR===>>>", error);
        }
    }

    const fetchHomeSettingData = async () => {
        try {
            const result = await getHomeSettingData();
            setHomeSettingData(result?.data);
        } catch (error) {
            console.log("FETCH SETTING ERROR===>>>", error);
        }
    }





    useEffect(() => {
        fetchSettingData();
        fetchProductsData();
        fetchMenuData();
        fetchCompanyData();
        fetchBannerData();
        fetchPageContentData();
        fetchHomeSettingData();
    }, []);

    useEffect(() => {
        const path = location?.pathname?.replace("/", "").toLowerCase();
        const matchedBanner = allBanners?.find(
            (banner) => banner?.menu?.menuUrl?.toLowerCase() === path
        );
        setMatchedBanner(matchedBanner || null);
    }, [location, allBanners]);

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => clearTimeout(timer);
    }, [location]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        allBanners,
        matchedBanner,
        loading,
        settingData,
        products,
        navData,
        isMobile,
        companyData,
        pageContent,
        homeSettingData,
    };
};

export default useAppHook;
