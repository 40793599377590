import { useEffect, useState } from "react";
import { scroller } from "react-scroll";

const useProductHook = (products) => {
    const [activeProduct, setActiveProduct] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        if (products?.length > 0) {
            setActiveProduct(products[0]?._id);
        }
    }, [products]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTabClick = (url, _id) => {
        setActiveProduct(_id);
        if (isMobile) {
            scroller.scrollTo("product-section", {
                duration: 200,
                delay: 0,
                smooth: "easeInOutQuart",
                offset: -100,
            });
        }
    };

    const activeProductData = products?.find(
        (product) => product?._id === activeProduct
    );


    return {
        activeProductData,
        activeProduct,
        setActiveProduct,
        handleTabClick,
    };
};

export default useProductHook;
