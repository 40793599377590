import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ImageLink } from "../../constants";

const OurCompanies = ({ companyData, company }) => {

  let title = "Eagle Group of<br /> Companies";
  if (company) {
    title = company?.title || title;
  }
  return (
    <>
      <div className="section-pt-80 section-pb-80">
        <div className="container we-believe-container">
          <motion.div className="row align-items-center mb-4"
            initial={{
              opacity: 0,
              y: 100
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: false }}
          >
            <div className="col-xl-9 col-lg-9 col-md-8 mb-4" >
              <div className="section-title">
                <h2 className="title" dangerouslySetInnerHTML={{ __html: title }}>

                </h2>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 text-lg-end text-start">
              <div className="we-believe-left">
                <p className="m-0 text-dark">Stronger Together</p>
              </div>
            </div>
          </motion.div>
          <div className="row gx-3 gy-4 justify-content-center">
            {companyData?.data?.length > 0 && companyData?.data?.map((company, index) => (
              (!company?.showInHome) ? null :
                <motion.div
                  className="col-sm-6 col-md-6 col-lg-4"
                  key={company?._id}
                  initial={{
                    opacity: 0,
                    x: 25,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 1,
                      delay: index * 0.2,
                    },
                  }}
                  viewport={{ once: false }}
                >
                  <Link to={`company/${company?.companyUrl}`}>
                    <div className="position-relative">
                      <img
                        src={ImageLink(company?.backgroundImg)}
                        alt={company?.title}
                        className="img-dark1"
                      />
                      <div className="text-center position-absolute px-3 company-text">
                        <h5 className="text-capitalize" dangerouslySetInnerHTML={{ __html: company?.name }}></h5>
                        <p className="company-dec" dangerouslySetInnerHTML={{ __html: company?.title }}></p>
                      </div>
                    </div>
                  </Link>
                </motion.div>
            ))}
          </div>
        </div >
      </div >
    </>
  );
};

export default OurCompanies;
