import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ImageLink } from './../../constants';

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array?.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const OurProducts = ({ products, product }) => {
  const productChunks = chunkArray(products, 4);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleImageClick = (product) => {
    if (selectedProduct && selectedProduct?._id === product?._id) {
      setSelectedProduct(null);
    } else {
      setSelectedProduct(product);
    }
  };

  let title = "Our Products";
  // let subtitle = "Explore More";

  if (product) {
    title = product?.title || title;
    // subtitle = product?.subtitle || subtitle
  }

  return (
    <section className="home-projects project-area project-style-three section-pt-80">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <motion.div
              className="section-title"
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
              viewport={{ once: false }}
            >
              <h2 className="title">{title}</h2>
            </motion.div>
          </div>
          <div className="col-xl-6 col-lg-6 text-end">
            <Link to="/products">
              <p className="explore-more">
                <span className="pe-3">Explore More</span>
                <i className="fa fa-arrow-right rotate-icon fs-5"></i>
              </p>
            </Link>
          </div>
        </div>

        {productChunks && productChunks?.map((chunk, chunkIndex) => (
          <div className="row d-flex flex-column" key={chunkIndex}>
            <div className="d-flex flex-row flex-wrap">
              {chunk && chunk?.map((product, index) => (
                <motion.div
                  className="col-lg-3 col-md-3 col-sm-12 col-12 homeProduct"
                  style={{ cursor: "pointer" }}
                  key={product?._id}
                  initial={{ opacity: 0, x: 25 }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: { duration: 1, delay: index * 0.2 },
                  }}
                  viewport={{ once: false }}
                >
                  <div
                    className={`project-item position-relative homeProductCursor ${selectedProduct && selectedProduct?._id === product?._id ? "active" : ""
                      }`}
                    onClick={() => handleImageClick(product)}
                  >
                    <div className="project-thumb1 img-brightness hover14">
                      <figure>
                        <img
                          src={ImageLink(product?.bannerImage)}
                          alt={product?.name}
                          className="w-100"
                        />
                      </figure>
                    </div>
                    <div className="project-content1 p-3">
                      <h6 className="title text-white letter-spacing-1 text-capitalize">
                        {product?.name}
                      </h6>
                    </div>
                  </div>

                  {selectedProduct && selectedProduct?._id === product?._id && (
                    <motion.div
                      className="row my-5 d-block d-md-none"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="selected-product col-lg-12">
                        <h2>{selectedProduct?.name}</h2>
                        <p dangerouslySetInnerHTML={{ __html: selectedProduct?.description }}></p>
                        <p><Link to={`/product/${selectedProduct?.url}`}>Read More</Link></p>
                      </div>
                    </motion.div>
                  )}
                </motion.div>
              ))}
            </div>

            {selectedProduct &&
              selectedProduct?.position >= chunkIndex * 4 + 1 &&
              selectedProduct?.position <= (chunkIndex + 1) * 4 && (
                <motion.div
                  className="row my-3 d-none d-md-block"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="selected-product col-lg-12">
                    <h2>{selectedProduct?.name}</h2>
                    <p dangerouslySetInnerHTML={{ __html: selectedProduct?.description }}></p>
                    <p className="text-decoration-underline underline-offset text-uppercase">
                      <Link to={`/product/${selectedProduct?.url}`}>Read More</Link>
                    </p>
                  </div>
                </motion.div>
              )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurProducts;

