import { useEffect, useState } from "react";
import { getMilestoneData } from "../../services/axios.function";

const useMilestoneHook = () => {
    const [milestones, setMilestones] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const fetchMilestoneData = async () => {
        try {
            const result = await getMilestoneData();
            setMilestones(result?.data);
        } catch (error) {
            console.log("FETCH MILESTONE ERROR===>>>", error);
        }
    };

    useEffect(() => {
        fetchMilestoneData();
    }, []);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkMobile();
        window.addEventListener("resize", checkMobile);
        return () => window.removeEventListener("resize", checkMobile);
    }, []);


    const truncateDescription = (description, wordLimit) => {
        const words = description.split(" ");
        if (words?.length <= wordLimit) return description;
        return words.slice(0, wordLimit).join(" ");
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const wordLimit = 12;

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    return {
        milestones,
        isMobile,
        truncateDescription,
        isExpanded,
        wordLimit,
        toggleDescription
    };
};

export default useMilestoneHook;
