// import { useRef, useState } from "react";
// import "./Investors.css";
// import useInvestorHook from "./Investors.hook";
// import { ImageLink } from "../../constants";
// import { Link } from "react-router-dom";

// const groupByInvestorType = (data) => {
//   return data.reduce((acc, curr) => {
//     if (!acc[curr.investorType]) {
//       acc[curr.investorType] = [];
//     }
//     acc[curr.investorType].push(curr);
//     return acc;
//   }, {});
// };

// const AccordionItem = ({ handleToggle, active, investorType, documents }) => {
//   const contentEl = useRef();

//   return (
//     <div className="rc-accordion-card">
//       <div className="rc-accordion-header">
//         <div
//           className={`rc-accordion-toggle ${active === investorType ? "active" : ""
//             }`}
//           onClick={() => handleToggle(investorType)}
//         >
//           <h5 className="rc-accordion-title">{investorType}</h5>
//           <i
//             className={`${active === investorType ? "fa fa-minus" : "fa fa-plus"
//               } rc-accordion-icon`}
//           ></i>
//         </div>
//       </div>
//       <div
//         ref={contentEl}
//         className={`rc-collapse ${active === investorType ? "show" : ""}`}
//         style={
//           active === investorType
//             ? { height: contentEl.current.scrollHeight }
//             : { height: "0px" }
//         }
//       >
//         <div className="rc-accordion-body">
//           <ol>
//             {documents.map(({ _id, title, document }) => (
//               <li key={_id}>
//                 <Link to={ImageLink(document)} target="_blank">
//                   <p>{title}</p>
//                 </Link>
//               </li>
//             ))}
//           </ol>
//         </div>
//       </div>
//     </div>
//   );
// };

// const Investors = () => {
//   const [active, setActive] = useState(null);
//   const { investorsData } = useInvestorHook();

//   const groupedData = groupByInvestorType(investorsData || []);

//   const handleToggle = (index) => {
//     if (active === index) {
//       setActive(null);
//     } else {
//       setActive(index);
//     }
//   };

//   return (
//     <div className="container mt-5 mb-5">
//       <div className="row justify-content-center">
//         <div className="col-lg-8">
//           {Object.keys(groupedData).length > 0 ? (
//             Object.entries(groupedData).map(([investorType, documents]) => (
//               <AccordionItem
//                 key={investorType}
//                 active={active}
//                 handleToggle={handleToggle}
//                 investorType={investorType}
//                 documents={documents}
//               />
//             ))
//           ) : (
//             <p>No investors data available.</p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Investors;

// import React from "react";
// import { Link } from "react-scroll";
// import "./Investors.css";

// const Investors = () => {
//   return <>
//     <div className="container">
//       <div className="row">
//         <div className="col-lg-6">
//           <div className="investor-card">
//             <div className="investor-card-header">
//               <h5>Quarterly Results</h5>
//             </div>
//             <div className="investor-card-body">
//               <ol>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link href="#">
//                     <p>Annual Report 2017-18</p>
//                   </Link>
//                 </li>
//               </ol>
//             </div>
//           </div>
//         </div>
//         <div className="col-lg-6"></div>
//         <div className="col-lg-6"></div>
//       </div>
//     </div>
//   </>;
// };

// export default Investors;

import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import useInvestorHook from "./Investors.hook";
import { Link } from "react-router-dom";
import { ImageLink } from "../../constants";

const Investors = () => {
  const { investorType, investorsData } = useInvestorHook();
  const [selectedType, setSelectedType] = useState("All");

  // Filter data based on selected type
  const filteredPdfs =
    selectedType === "All"
      ? investorsData
      : investorsData.filter((pdf) => pdf.investorType === selectedType);

  return (
    <Container className="py-4">
      {/* Header */}
      <header className="mb-4">
        <h1>Investor Documents</h1>
        <p className="text-muted">
          Explore our latest reports and presentations.
        </p>
      </header>

      {/* Type Selector */}
      <Form className="mb-4">
        <FormGroup>
          <Input
            type="select"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            style={{ width: "280px" }}
            className="custom-select-ip"
          >
            <option value="All">All Documents</option>
            {Array.isArray(investorType) &&
              investorType.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
          </Input>
        </FormGroup>
      </Form>

      {/* PDF List */}
      <Row>
        {filteredPdfs?.length > 0 ? (
          filteredPdfs.map((pdf) => (
            <Col key={pdf.id} sm="12" md="6" lg="4" className="mb-4">
              <Card>
                <CardBody>
                  <h5 className="text-capitalize mb-1">{pdf.title}</h5>
                  <p className="text-muted small mb-2">
                    Report Type: {pdf.investorType}
                  </p>
                  <div className="d-flex gap-2">
                    <Link
                      to={ImageLink(pdf.document)}
                      // to="#"
                      target="_blank"
                      className="view-pdf-link"
                      style={{
                        textDecoration: "underline",
                        textUnderlineOffset: "5px",
                      }}
                    >
                      View PDF
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <p>No documents found.</p>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Investors;
