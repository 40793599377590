export const BASE_URL = process.env.REACT_APP_API;


export const SLIDERURL = BASE_URL + "slider/getWithoutPaginationSlider";
export const COUNTERURL = BASE_URL + "counter/getWithoutPaginationCounter";
export const COMPANYURL = BASE_URL + "company/getWithoutPaginationCompany";
export const BANNERURL = BASE_URL + "banner/getWithoutPaginationBanner";
export const MANAGEMENTURL = BASE_URL + "management/getWithoutPaginationManagement";
export const MILESTONEURL = BASE_URL + "milestone/getWithoutPaginationMilestone";
export const SOCIALMEDIAURL = BASE_URL + "socialMedia/getWithoutPaginationSocialMedia";
export const CONTACTURL = BASE_URL + "enquiry/createEnquiry";
export const SETTINGURL = BASE_URL + "setting/getSettings";
export const PRODUCTURL = BASE_URL + "product/getWithoutPaginationProduct";
export const NAVBARURL = BASE_URL + "menu/getWithoutPaginationMenu";
export const REPORTCATEGORYURL = BASE_URL + "reportCategory/getWithoutPaginationCategory";
export const CATEGORYFILEURL = BASE_URL + "categoryFile/getWithoutPaginationCategoryFile"
export const EXCELLENCEURL = BASE_URL + "excellence/getWithoutPaginationExcellence"
export const PAGECONTENTURL = BASE_URL + "content/getWithoutPaginationContent"
export const HOMESETTINGURL = BASE_URL + "homeSettings/getHomeSettings"
export const INVESTORSURL = BASE_URL + "investor/getAllInvestorWithoutPagination"
export const INVESTORSTYPEURL = BASE_URL + "investor/getInvestorTypes"

