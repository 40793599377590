import { Route, Routes } from "react-router-dom";
import "./App.css";

import Loader from "./Components/Loader";
import Scroll from "./Components/Scroll";
import ScrollToTop from "./Components/ScrollToTop";
import Navbar from "./Pages/Navbar/Navbar";
import Footer from "./Pages/Footer/Footer";
import Homepage from "./Pages/Home/Homepage";
import Aboutpage from "./Pages/About/Aboutpage";
import Milestone from "./Pages/Milestone/Milestone";
import Infrastructure from "./Pages/Infrastructure/Infrastructure";
import Management from "./Pages/Management/Management";
import Product from "./Pages/Product/Product";
import ProductDetails from "./Pages/Product/ProductDetails";
import Contact from "./Pages/Contact/Contact";
import Banner from "./Components/Banner";
import useAppHook from "./App.hook";
import { HelmetProvider } from "react-helmet-async";
import Meta from "./Components/Meta";
import ErrorPage from "./Pages/ErrorPage";
import CompanyDetails from "./Pages/Company/CompanyDetails";
import Investors from "./Pages/Investors/Investors";

function App() {

  const {
    allBanners,
    matchedBanner,
    loading,
    settingData,
    products,
    navData,
    companyData,
    isMobile,
    pageContent,
    homeSettingData
  } = useAppHook()

  return (
    <HelmetProvider>
      <div className="App">
        {loading && <Loader />}
        <Scroll />
        <ScrollToTop />
        <Navbar settingData={settingData} navData={navData} />
        {matchedBanner && <Banner bannerData={matchedBanner} />}


        <Meta navData={navData} settingData={settingData} products={products} />


        <Routes>
          <Route path="/" element={<Homepage products={products} homeSettingData={homeSettingData} pageContent={pageContent} companyData={companyData} isMobile={isMobile} />} />
          <Route path="profile" element={<Aboutpage />} />
          <Route path="milestones" element={<Milestone pageContent={pageContent} />} />
          <Route path="infrastructure" element={<Infrastructure companyData={companyData} />} />
          <Route path="management" element={<Management navData={navData} pageContent={pageContent} />} />
          <Route path="investors" element={<Investors />} />
          <Route path="products" element={<Product products={products} />} />
          <Route path={`/product/:slug`} element={<ProductDetails products={products} allBanners={allBanners} isMobile={isMobile} />} />
          <Route path="contact-us" element={<Contact companyData={companyData} />} />
          <Route path={`/company/:companyUrl`} element={<CompanyDetails />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <Footer
          settingData={settingData}
          products={products}
          navData={navData}
          companyData={companyData}
        />
      </div>
    </HelmetProvider>
  );
}

export default App;
