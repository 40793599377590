import { useEffect, useRef, useState } from "react";
import { getCounterData, getSliderData } from "../../services/axios.function";
import Odometer from "odometer";

const useHomeHook = () => {

    const [counters, setCounters] = useState([]);
    const odometerRefsDesktop = useRef([]);
    const odometerRefsMobile = useRef([]);
    const countersSectionRef = useRef(null);

const fetchCounterData = async () => {
    try {
        const result = await getCounterData();
        if (result?.data !== counters) {
            setCounters(result?.data);
        }
    } catch (error) {
        console.log("FETCH COUNTERS ERROR===>>>", error);
    }
};

    useEffect(() => {
        fetchCounterData()
    }, [])


    useEffect(() => {
        const initializeOdometers = (refs) => {
            counters?.forEach((counter, index) => {
                if (refs.current[index]) {
                    const odometerInstance = new Odometer({
                        el: refs.current[index],
                        value: 0,
                        format: "(,ddd)",
                        theme: "default",
                    });

                    setTimeout(() => {
                        odometerInstance.update(counter?.number);
                    }, 300);
                }
            });
        };

        const observer = new IntersectionObserver(
            (entries) => {
                entries?.forEach((entry) => {
                    if (entry.isIntersecting) {
                        initializeOdometers(odometerRefsDesktop);
                        initializeOdometers(odometerRefsMobile);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (countersSectionRef.current) {
            observer.observe(countersSectionRef.current);
        }

        return () => {
            if (countersSectionRef.current) {
                observer.unobserve(countersSectionRef.current);
            }
        };
    }, [counters]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return {
        counters,
        odometerRefsDesktop,
        odometerRefsMobile,
        countersSectionRef,
        settings,
    }
};

export default useHomeHook;


